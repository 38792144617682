import React from "react";
import logo from "../assets/logo.svg";
import "./Hero.css"; // Import a CSS file for styling

const Hero = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <div style={{ flex: 1, marginRight: "70px" }}>
      <img
        src={logo}
        alt="EveryPeer logo"
        width="400"
        className="spin-logo" // Apply the animation class here
      />
    </div>
    <div style={{ flex: 4 }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1 style={{ display: "inline" }}>Welcome to EveryPeer</h1>
      </div>
      <br></br>
      <p className="lead">AI Powered Software Defined Networks</p>
    </div>
  </div>
);

export default Hero;
