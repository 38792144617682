import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem as MuiMenuItem,
  TextField,
  FormGroup,
  Tooltip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import "./HologramSwitch.css"; // Import Hologram CSS file
import "./responsive-styles.css"; // Import Responsive Styles CSS file
import MoreVertIcon from "@mui/icons-material/MoreVert"; // Importing the three dots icon
import logo from "../assets/logo.svg";
import CoPresentIcon from "@mui/icons-material/CoPresent";

import HologramSwitch from "../components/HologramSwitch.js";
import NodeStatus from "../components/NodeStatus";
import {
  fetchUserData,
  fetchEpnData,
  fetchCombinedConData,
} from "../components/fetchFunctions";
import { EP_API_URL } from "../components/apiURLConstant";

//***********IMPORT REFACTORED CODE STRUCTURES */

//***********   DATATABLE START  *************/
const DataTable = () => {
  const [data] = useState([]);
  const [userData, setUserData] = useState(null);
  const [checkedStates, setCheckedStates] = useState(
    new Array(data.length).fill(false)
  );
  const [nodeData, setNodeData] = useState({});
  const [pubIps, setPubIps] = useState({});
  const [pubIpsLoading, setPubIpsLoading] = useState(true);
  const [location, setLocation] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [holPwds, setHolPwds] = useState(() => {
    const savedPwds = localStorage.getItem("holPwds");
    return savedPwds ? JSON.parse(savedPwds) : {};
  });
  const [loading, setLoading] = useState(true);
  const [epnData, setEpnData] = useState(null);
  const { user } = useAuth0();
  const userId = user ? user.sub : null;
  const [isPopupBlocked, setIsPopupBlocked] = useState(false);
  const now = new Date().toLocaleString();
  const [editMode, setEditMode] = useState({ index: null, name: "" });
  const [combinedConData, setCombinedConData] = useState([]);
  const [anchorEls, setAnchorEls] = useState([]);

  const handleEditChange = (e) => {
    setEditMode((prev) => ({ ...prev, name: e.target.value }));
  };

  const handleEditSubmit = async (orgIndex, nodeIndex) => {
    const node = epnData[orgIndex].data[nodeIndex];
    const updatedNodeName = { node_name: editMode.name };

    try {
      const response = await axios.put(
        `${EP_API_URL}/api/putNodeName/${node.node_id}`,
        updatedNodeName
      );

      if (response.data) {
        const newData = [...epnData];
        newData[orgIndex].data[nodeIndex].node_name = editMode.name;
        setEpnData(newData);
        // console.log("Node name updated successfully");
      }
    } catch (error) {
      console.error(`Failed to update node name for ${node.node_name}`, error);
    }
    setEditMode({ index: null, name: "" });
  };

  const handleOpenPopup = (url) => {
    const timeout = 5000;
    const popup = window.open(url, "_blank", "noopener,noreferrer");

    if (!popup) {
      setIsPopupBlocked(true);
      return;
    }

    let timerId = null;

    const checkStatus = () => {
      fetch(url, { method: "HEAD" })
        .then((response) => {
          if (response.ok) {
            popup.focus();
          } else if (response.status >= 500 && response.status < 600) {
            setTimeout(checkStatus, 1000);
          }
        })
        .catch((error) => {
          console.error("Error fetching URL:", error);
        })
        .finally(() => {
          clearTimeout(timerId);
        });
    };

    timerId = setTimeout(() => {
      popup.close();
      setIsPopupBlocked(true);
    }, timeout);

    checkStatus();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await fetchUserData(userId, EP_API_URL);
        setUserData(userData);

        const orgIds = userData.data.org_id_perm_list.map(
          (orgObj) => orgObj.org_id
        );
        const epnDataArray = await Promise.all(
          orgIds.map((orgId) => fetchEpnData(orgId, EP_API_URL))
        );
        setEpnData(epnDataArray);

        if (orgIds.length > 0) {
          const fetchedCombinedConData = await fetchCombinedConData(
            orgIds,
            EP_API_URL
          );
          setCombinedConData(fetchedCombinedConData);
        } else {
          console.error("No valid organization IDs found.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false once data fetching is complete
      }
    };
    if (userId) {
      fetchData();
    }
  }, [userId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userId && userData) {
        fetchCombinedConData(
          userData.data.org_id_perm_list.map((orgObj) => orgObj.org_id),
          EP_API_URL
        )
          .then(setCombinedConData)
          .catch((error) =>
            console.error("Error fetching combined con data:", error)
          );
      }
    }, 6000);
    return () => clearInterval(interval);
  }, [userId, userData]);

  useEffect(() => {
    const fetchAllNodeStatuses = async () => {
      try {
        const response = await axios.get(
          `${EP_API_URL}/api/nodeStatus/${userId}`
        );
        const nodes = response.data.data?.data;
        if (Array.isArray(nodes)) {
          setNodeData(
            nodes.reduce((acc, node) => ({ ...acc, [node.node_id]: node }), {})
          );
        }
      } catch (error) {
        console.error("Failed to fetch node statuses", error);
      }
    };

    if (userId) {
      fetchAllNodeStatuses();
      const initialFetchTimeout = setTimeout(fetchAllNodeStatuses, 10000);
      const intervalId = setInterval(fetchAllNodeStatuses, 5 * 60 * 1000);
      return () => {
        clearTimeout(initialFetchTimeout);
        clearInterval(intervalId);
      };
    }
  }, [userId]);

  const fetchNodeStatuses = async () => {
    try {
      const nodeIds = epnData.flatMap((orgData) =>
        orgData.data.map((node) => node.node_id)
      );
      const initialPubIps = {};
      nodeIds.forEach((nodeId) => {
        initialPubIps[nodeId] = "loading...";
      });
      setPubIps(initialPubIps);

      const requests = nodeIds.map((nodeId) =>
        axios.get(`${EP_API_URL}/api/getstatus/${nodeId}`)
      );
      const responses = await Promise.all(requests);

      const updatedPubIps = { ...initialPubIps };

      responses.forEach((response) => {
        // console.log("Response Data:", response.data); // Debugging
        const statusData = response.data?.statusData;
        if (
          statusData &&
          Array.isArray(statusData.data) &&
          statusData.data.length > 0
        ) {
          const nodeData = statusData.data[0]; // This is where your node_id and payload are
          const payload = nodeData.payload;
          if (payload && payload[0]?.pub_ip) {
            updatedPubIps[nodeData.node_id] = payload[0].pub_ip;
          } else {
            updatedPubIps[nodeData.node_id] = "N/A";
          }
        } else {
          updatedPubIps[response.config.url.split("/").pop()] = "N/A"; // Use node_id from URL
        }
      });

      setPubIps(updatedPubIps);
      // console.log("Updated Public IPs:", updatedPubIps); // Debugging
    } catch (error) {
      console.error("Failed to fetch node statuses", error);
    } finally {
      setLoading(false);
      setPubIpsLoading(false);
    }
  };

  useEffect(() => {
    if (epnData) {
      fetchNodeStatuses();
    }
  }, [epnData]);

  const handleMenuOpen = (event, index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleMenuClose = (index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  const handleConfigurationButtonClick = (node) => {
    setSelectedSiteForConfig(node);
    setConfigFormOpen(true);
  };

  const [selectedSiteForConfig, setSelectedSiteForConfig] = useState("");
  const [isConfigFormOpen, setConfigFormOpen] = useState(false);
  const [configFormData, setConfigFormData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchLocationData = async () => {
      if (
        !selectedSiteForConfig ||
        !selectedSiteForConfig.node_id ||
        !selectedSiteForConfig.org_id
      ) {
        return;
      }

      const response = await fetch(
        `${EP_API_URL}/api/getConfigPut?node_id=${selectedSiteForConfig.node_id}&org_id=${selectedSiteForConfig.org_id}`,
        {
          method: "GET",
          headers: { Accept: "application/json" },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCurrentLocation(data.data.notes);
        setLocation(data.data.notes);
      } else {
        console.error("Failed to fetch configuration:", response.statusText);
      }
    };

    if (isConfigFormOpen) {
      fetchLocationData();
    }
  }, [selectedSiteForConfig, isConfigFormOpen]);

  const handleInputChange = (event) => {
    let { value } = event.target;
    value = value.replace(/[^a-zA-Z0-1, ]/g, "");
    setLocation(value);
  };

  const handleConfigFormSubmit = async () => {
    const configNode_id = selectedSiteForConfig.node_id;
    const org_id = selectedSiteForConfig.org_id;

    if (!org_id || !configNode_id) {
      setErrorMessage(
        "Organization ID or Node ID is undefined. Please select a valid site."
      );
      return;
    }

    const configPut = {
      data: { node_name: selectedSiteForConfig.node_name, notes: location },
    };
    setIsSubmitting(true);
    try {
      const response = await axios.put(
        `${EP_API_URL}/api/configPut?org_id=${org_id}&node_id=${configNode_id}`,
        configPut
      );
      if (response.status === 200) {
        setConfigFormOpen(false);
        setLocation("");
      } else {
        setErrorMessage(`Error updating node: ${response.statusText}`);
      }
    } catch (error) {
      setErrorMessage(`Error updating node: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const tableStyle = {
    margin: "50px",
    width: "100%",
    marginBottom: "70px",
    fontFamily: "Nunito, sans-serif",
    borderRadius: "20px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    justifyContent: "center",
    alignItems: "center",
  };

  const customTheme = createTheme({
    typography: {
      fontFamily: "Nunito, sans-serif",
    },
  });

  // console.log("Loading:", loading); // Debug loading state
  // console.log("EPN Data:", epnData); // Debug EPN data
  // console.log("Public IPs State:", pubIps); // Debug public IPs state

  return (
    <center>
      <ThemeProvider theme={customTheme}>
        {(loading || pubIpsLoading) && (
          <div>
            <img src={logo} className="App-logo" alt="logo" />
            <h3 className="loading-text">
              Loading the Everypeer Experience...
            </h3>
          </div>
        )}
        <div className="table-container" style={tableStyle}>
          {!loading && !pubIpsLoading && epnData && epnData.length > 0 && (
            <Table sx={{ fontFamily: "Nunito, sans-serif !important" }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Node Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Node Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Public IP</b>
                  </TableCell>
                  <TableCell>
                    <b>EveryPeer IP</b>
                  </TableCell>
                  <TableCell>
                    <b>Hologram</b>
                  </TableCell>
                  <TableCell>
                    <b>Share</b>
                  </TableCell>
                  <TableCell>
                    <b>Menu</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {epnData.map((orgData, orgIndex) =>
                  orgData.data.map((node, nodeIndex) => (
                    <TableRow key={`org_${orgIndex}_node_${nodeIndex}`}>
                      <TableCell>
                        <Tooltip
                          title={`Node ID: ${node.node_id}, Org ID: ${node.org_id}`}
                        >
                          <span>
                            {editMode.index ===
                            `org_${orgIndex}_node_${nodeIndex}` ? (
                              <>
                                <TextField
                                  size="small"
                                  value={editMode.name}
                                  onChange={handleEditChange}
                                  onBlur={() =>
                                    handleEditSubmit(orgIndex, nodeIndex)
                                  }
                                  autoFocus
                                />
                                <IconButton
                                  onClick={() =>
                                    handleEditSubmit(orgIndex, nodeIndex)
                                  }
                                  size="small"
                                >
                                  {/* <CheckIcon /> */}
                                </IconButton>
                              </>
                            ) : (
                              <>{node.node_name}</>
                            )}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {nodeData[node.node_id] ? (
                          <NodeStatus nodeStatus={nodeData[node.node_id]} />
                        ) : (
                          <span>Loading status...</span>
                        )}
                      </TableCell>
                      <TableCell>{pubIps[node.node_id] || "N/A"}</TableCell>
                      <TableCell>{node.nb_ip}</TableCell>
                      <TableCell>
                        {node.soft_node === "yes" ? (
                          <div>N/A</div>
                        ) : (
                          <HologramSwitch
                            key={`${orgIndex}_${nodeIndex}`}
                            node={node}
                            combinedConData={combinedConData}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {node.soft_node === "yes" ? (
                          <div>N/A</div>
                        ) : (
                          <>
                            <Tooltip
                              title={`Click to join session. Right click to share session: https://hologram.everypeer.net/${
                                node.hol_uri
                              }/?usr=hologram&pwd=${
                                combinedConData.data
                                  .find((item) => item.name === node.node_id)
                                  ?.containers.find(
                                    (container) =>
                                      container.image ===
                                      "everypeer/hologram_xfce_x86:latest"
                                  )?.pwd || "default_password"
                              }`}
                            >
                              <IconButton
                                onClick={() =>
                                  handleOpenPopup(
                                    `https://hologram.everypeer.net/${
                                      node.hol_uri
                                    }/?usr=hologram&pwd=${
                                      combinedConData.data
                                        .find(
                                          (item) => item.name === node.node_id
                                        )
                                        ?.containers.find(
                                          (container) =>
                                            container.image ===
                                            "everypeer/hologram_xfce_x86:latest"
                                        )?.pwd || "default_password"
                                    }`
                                  )
                                }
                                color={
                                  combinedConData.data.some(
                                    (item) =>
                                      item.name === node.node_id &&
                                      item.containers.some(
                                        (container) =>
                                          container.image ===
                                            "everypeer/hologram_xfce_x86:latest" &&
                                          container.state === "running"
                                      )
                                  )
                                    ? "success"
                                    : combinedConData.data.some(
                                        (item) =>
                                          item.name === node.node_id &&
                                          item.containers.some(
                                            (container) =>
                                              container.state === "paused"
                                          )
                                      )
                                    ? "disabled"
                                    : "disabled"
                                }
                                aria-label="join"
                                disabled={
                                  !combinedConData.data
                                    .find((item) => item.name === node.node_id)
                                    ?.containers.find(
                                      (container) =>
                                        container.image ===
                                          "everypeer/hologram_xfce_x86:latest" &&
                                        container.state === "running"
                                    )?.pwd
                                }
                              >
                                <CoPresentIcon />
                              </IconButton>
                            </Tooltip>
                            {isPopupBlocked && (
                              <div style={{ fontSize: "12px" }}></div>
                            )}
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          edge="end"
                          color="inherit"
                          aria-label="menu"
                          onClick={(event) =>
                            handleMenuOpen(event, `${orgIndex}_${nodeIndex}`)
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEls[`${orgIndex}_${nodeIndex}`]}
                          open={Boolean(anchorEls[`${orgIndex}_${nodeIndex}`])}
                          onClose={() =>
                            handleMenuClose(`${orgIndex}_${nodeIndex}`)
                          }
                        >
                          <MenuItem
                            onClick={() => handleConfigurationButtonClick(node)}
                          >
                            Edit Location
                          </MenuItem>
                          <MenuItem>Disable Node</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}

          <Dialog
            open={isConfigFormOpen}
            onClose={() => setConfigFormOpen(false)}
          >
            <DialogTitle>Edit Location</DialogTitle>
            <DialogContent>
              <Typography variant="h8">
                <b>Node:</b>
                <p>{selectedSiteForConfig.node_name}</p>
                <b>Current Location:</b>
                <p>{currentLocation}</p>
              </Typography>

              <FormGroup>
                <b>Change Location:</b>
                <TextField
                  label="Location"
                  fullWidth
                  value={location}
                  onChange={(e) => handleInputChange(e, "location")}
                />
              </FormGroup>
              <p>*fields are required</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfigFormOpen(false)}>Cancel</Button>
              <Button
                onClick={handleConfigFormSubmit}
                color="primary"
                type="submit"
                disabled={!location}
              >
                Change Location
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </ThemeProvider>
    </center>
  );
};

export default DataTable;
