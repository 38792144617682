const contentData = [
  {
    title: "AI Features",
    link: "https://everypeer.net",
    description:
      "EveryPeer features include powerful AI capabilities that allow you to manage your network with ease."
  },
  {
    title: "Hologram",
    link: "https://everypeer.net",
    description:
      "Hologram allows you to connect to your devices from anywhere in the world. Hologram is built on top of the latest technologies."
  },
  {
    title: "AI Defined Networks",
    link: "https://everypeer.net",
    description:
      "EveryPeer is an AI Powered Software Defined Network that provides a secure, reliable, and scalable platform for your applications. EveryPeer is built on top of the latest technologies."
  },
  {
    title: "Visit our website",
    link: "https://everypeer.net",
    description:
      "Visit us to learn more about our products and services."
  }
];

export default contentData;
