// fetchFunctions.js
export const fetchUserData = async (userId, EP_API_URL) => {
  const response = await fetch(`${EP_API_URL}/api/getUserData/${userId}`);
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return response.json();
};

// export const fetchOrgData = async (orgId, EP_API_URL) => {
//   const response = await fetch(`${EP_API_URL}/api/getOrgData/${orgId}`);
//   if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
//   return response.json();
// };

export const fetchEpnData = async (orgId, EP_API_URL) => {
  const response = await fetch(`${EP_API_URL}/api/getEpnData/${orgId}`);
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return response.json();
};

export const fetchCombinedConData = async (orgIds, EP_API_URL) => {
  const params = new URLSearchParams();
  orgIds.forEach((id) => params.append("org_ids", id));
  const response = await fetch(
    `${EP_API_URL}/api/getConData/?${params.toString()}`
  );
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return response.json();
};
