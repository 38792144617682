// HologramSwitch.js
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { EP_API_URL } from "../components/apiURLConstant";

const HologramSwitch = ({ node, combinedConData }) => {
  const lastUserInteractionTime = useRef(Date.now());
  const [isNodeOnline, setIsNodeOnline] = useState(() => {
    return combinedConData.data
      .filter((item) => item.name === node.node_id)
      .flatMap((item) => item.containers)
      .some((container) => container.state === "running");
  });
  const [isSwitchOn, setIsSwitchOn] = useState(false); // Set initial state to OFF
  const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false);
  const [loadingDialogTitle, setLoadingDialogTitle] = useState("");
  const [endpointId, setEndpointId] = useState(null);
  const [containerId, setContainerId] = useState(null);

  useEffect(() => {
    const checkContainerStatus = () => {
      const nodeData = combinedConData.data.find(
        (item) => item.name === node.node_id
      );
      const containerData = nodeData
        ? nodeData.containers.find(
            (container) =>
              container.image === "everypeer/hologram_xfce_x86:latest"
          )
        : null;
      setIsNodeOnline(!!containerData);
      setEndpointId(nodeData ? nodeData.id : null);
      setContainerId(containerData ? containerData.container_id : null);

      if (Date.now() - lastUserInteractionTime.current > 10000) {
        setIsSwitchOn(!!containerData && containerData.state === "running");
        localStorage.setItem(
          `switchState_${node.node_id}`,
          !!containerData && containerData.state === "running" ? "1" : "0"
        );
      }
    };

    checkContainerStatus();
    const intervalId = setInterval(checkContainerStatus, 10000);
    return () => clearInterval(intervalId);
  }, [node.node_id, combinedConData.data]);

  const handleSwitchChange = async () => {
    lastUserInteractionTime.current = Date.now();
    const newIsSwitchOn = !isSwitchOn;
    setIsSwitchOn(newIsSwitchOn);
    localStorage.setItem(
      `switchState_${node.node_id}`,
      newIsSwitchOn ? "1" : "0"
    );

    if (newIsSwitchOn) {
      await handlePauseResume("resume");
    } else {
      await handlePauseResume("pause");
    }
  };

  const handlePauseResume = async (command) => {
    setIsLoadingDialogOpen(true);
    setLoadingDialogTitle(
      command === "pause" ? "Pausing Hologram" : "Resuming Hologram"
    );
    try {
      const endpoint = `${EP_API_URL}/api/v1/portainer/hologram/reuse/${endpointId}/${command}/${containerId}`;
      await axios.get(endpoint);
      if (command === "resume") {
        setIsLoadingDialogOpen(true);
        setLoadingDialogTitle("Launching Hologram");
        const password =
          combinedConData.data
            .find((item) => item.name === node.node_id)
            ?.containers.find(
              (container) =>
                container.image === "everypeer/hologram_xfce_x86:latest"
            )?.pwd || "default_password";
        const url = `https://hologram.everypeer.net/${node.hol_uri}/?usr=hologram&pwd=${password}`;
        openDelayedPopup(url, 8000);
      }
    } catch (error) {
      console.error(`Error processing hologram details for ${command}:`, error);
    } finally {
      setIsLoadingDialogOpen(false);
    }
  };

  const openDelayedPopup = (url, delay) => {
    setTimeout(() => {
      if (!window.open(url, "_blank")) {
        setIsPopupBlocked(true);
      }
    }, delay);
  };

  return (
    <div className="switch-container">
      <button
        onClick={handleSwitchChange}
        className={`toggle-button ${isSwitchOn ? "on" : "off"}`}
      >
        <span className="button-label on">ON</span>
        <span className="button-label off">OFF</span>
      </button>
      <Dialog open={isLoadingDialogOpen}>
        <DialogTitle>{loadingDialogTitle}</DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress disableShrink />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default HologramSwitch;
