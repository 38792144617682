import React from "react";
import logo from "../assets/logo.svg";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <img
      src={logo}
      alt="EveryPeer Logo"
      style={{ width: "40px", height: "50px" }}
    />
    <p>
      EveryPeer Portal - QA v2.31 | Visit{" "}
      <a href="https://everypeer.com" style={{ color: "green" }}>
        EveryPeer
      </a>
    </p>
  </footer>
);

export default Footer;
