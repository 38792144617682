// NodeStatus.js
import React from "react";
import { Tooltip } from "@mui/material";

const NodeStatus = ({ nodeStatus }) => {
  let status;
  if (nodeStatus.connected === undefined || nodeStatus.connected === null) {
    status = {
      state: "Loading",
      lastSeen: "Checking status...",
    };
  } else {
    status = {
      state: nodeStatus.connected ? "Online" : "Offline",
      lastSeen: `Last seen: ${new Date(nodeStatus.last_seen * 1000).toLocaleString()}`,
    };
  }

  return (
    <Tooltip title={status.lastSeen} placement="top">
      <span>
        <span
          className={`status-dot ${
            status.state === "Online" ? "online-dot" : status.state === "Offline" ? "offline-dot" : "loading-dot"
          }`}
        ></span>
        {status.state}
      </span>
    </Tooltip>
  );
};

export default NodeStatus;
